import './bootstrap';

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function() {
    var mobileMenuToggle = $('#mobileMenuToggle');
    var headerNav = $('header nav');
    var mobileMenu = $('#mobileMenu');

    mobileMenuToggle.click(function(event) {
        event.stopPropagation();
        if (!mobileMenu.hasClass('show')) {
            mobileMenu.slideDown(300, function() {
                headerNav.addClass('mobile-menu-open');
            });

        } else {
            headerNav.removeClass('mobile-menu-open');
        }

        setTimeout(function() {
            if (mobileMenu.height() == 0) {
                headerNav.removeClass('mobile-menu-open');
            }

        }, 300);
    });

    mobileMenu.find('.nav-link').click(function() {
        mobileMenu.removeClass('show');
        headerNav.removeClass('mobile-menu-open');

    });

    $(document).click(function(event) {
        if (mobileMenu.hasClass('show') && !mobileMenu.is(event.target) && mobileMenu.has(event.target).length === 0 && !mobileMenuToggle.is(event.target)) {
            mobileMenu.slideUp(300, function() {
                mobileMenu.removeClass('show');
                headerNav.removeClass('mobile-menu-open');
            });
        }
    });
});


$(document).ready(function() {
    if (!Cookies.get('ageVerified')) {
        // $('.verification-age').fadeIn();
        $('.verification-age').css('display', 'flex');
    }

    $('#over21Btn').click(function() {
        Cookies.set('ageVerified', true);
        $('.verification-age').fadeOut();
    });

    $('#under21Btn').click(function() {
        window.location.replace("https://www.google.com");
    });
});


$(document).ready(function(){
    $('.marquee-content').attr('data-text', $('.marquee-content').text());

    $('.drp-buttons input[type="radio"]').change(function(){
        $('.drp-buttons label').removeClass('active');
        $(this).closest('label').addClass('active');
    });
});


$(document).ready(function(){
    $(window).scroll(function(){
        $('.animated-element').each(function(){
            var position = $(this).offset().top;
            var windowHeight = $(window).height();
            var scroll = $(window).scrollTop();
            if (scroll + windowHeight > position) {
                $(this).addClass('animate__animated').css('animation-duration', '1s');
            }
        });
    });
});


$('.formVerify').submit(function (e) {
    e.preventDefault();
    $('#verifyMessage').hide();
    var formData = $(this).serialize();

    $.ajax({
        type: 'POST',
        url: '/form-verify',
        data: formData,
        success: function (response) {
            if(response.success) {
                $('#verifyMessage').show();
                $('#verifyMessage .message').html('<div>Verification Successful!</div>');
                $('.formVerify')[0].reset();

                setTimeout(function () {
                    $('#verifyMessage').fadeOut();
                }, 3000);

            } else {
                $('#verifyMessage').show();
                $('#verifyMessage .message').html('<div>Verification Failed!.<br>Please try again.</div>');

            }
        },
        error: function (xhr, status, error) {
            $('#verifyMessage').show();
            $('#verifyMessage .message').html('<div>Verification Failed!.<br>Please try again.</div>');
        }
    });
});

$('.formContact').submit(function (e) {
    e.preventDefault();
    $('#verifyMessage').hide();
    var formData = $(this).serialize();

    $.ajax({
        type: 'POST',
        url: '/feedback',
        data: formData,
        success: function (response) {
            $('#feedbackMessage').show();
            $('#feedbackMessage .message').html('<div>Thank you! Your message has been sent.</div>');
            $('.formContact')[0].reset();

            setTimeout(function () {
                $('#feedbackMessage').fadeOut();
            }, 3000);
        },
        error: function (xhr, status, error) {
            $('#feedbackMessage').show();
            $('#feedbackMessage .message').html('<div>An error has occurred. Please try again.</div>');
        }
    });
});

$('.formSubscribe').submit(function (e) {
    e.preventDefault();
    var formData = $(this).serialize();

    $.ajax({
        type: 'POST',
        url: '/subscribe',
        data: formData,
        success: function (response) {
            $('.formSubscribe')[0].reset();

        },
        error: function (xhr, status, error) {
        }
    });
});

var transformations = [
    'rotate(-10deg) scale(0.9)',
    'rotate(10deg) scale(0.8)',
    'rotate(0deg) scale(1)',
    'rotate(5deg) scale(1.05)',
    'rotate(-25deg) scale(1)',
    'rotate(-5deg) scale(1.1)',
];


function applyRandomTransformation() {
    $('.flavors__items__product img').each(function(){
        var randomIndex = Math.floor(Math.random() * transformations.length);
        var randomTransformation = transformations[randomIndex];
        $(this).css('transform', randomTransformation);
    });
}
setInterval(applyRandomTransformation, 1000);
